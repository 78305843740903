export default function NotFound () {
  return (
    <h1
      style={{
        margin: '80px auto',
        width: 'fit-content'
      }}
    >
      К сожалению, такой страницы не существует
    </h1>
  )
}
